import React, { Component } from "react";
import Cubes from '../resources/images/cubes.svg';
import Handbox from '../resources/images/hand-holding-box.svg';
import Papeclip from '../resources/images/paperclip.svg';
import Arrow from '../resources/images/arrow-right-arrow-left.svg';
import Bricks from '../resources/images/trowel-bricks.svg';
import Boxes from '../resources/images/box-circle-check.svg';
import People from '../resources/images/people-arrows-left-right.svg';
import Flag from '../resources/images/flag-checkered.svg';
import "../styles/PlanningFeatures.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const featureList = [
  {
    featureIcon: Cubes,
    featureTitle: "مدیریت کالای تامین پیمانکار",
    featureDescription:
      "در پروژه هائی که تامین کالا و مصالح به عهده پیمانکار می باشد میتوان گردش کالا و مصالح تامین شده توسط پیمانکار را ثبت و از طریق گزارشات رصد نمود.",
  },
  {
    featureIcon: Handbox,
    featureTitle: "مدیریت کالای تامین کارفرما",
    featureDescription:
    "درصورتیکه کالا و مصالح را کارفرما تامین و بصورت امانی در اختیار پیمانکار قرار دهد میتوان مقدار کالای دریافتی و مصرفی را ثبت و گزارش مانده بدهکاری به کارفرما را دریافت کرد."
  },
  {
    featureIcon: Papeclip,
    featureTitle: "ضمیمه تصاویر و مستندات کالا",
    featureDescription:
      "برای مصالح و کالاهائی که نیاز به نگهداری مستندات فنی و ثبت تصاویر از آن ها وجود دارد، امکان بارگذاری انواع تصاویر و مستندات از طریق گوشی هوشمند فراهم آمده است.",
  },
  {
    featureIcon: Arrow,
    featureTitle: "انتقال مصالح بین پروژه ها",
    featureDescription:
      "بسیار پیش  می آید که کالا و مصالحی در یک پروژه موجوداست اما همزمان در پروژه ای دیگر مورد نیاز است که میتوان آن را به پروزه دیگر منتقل کرد و سابقه آن را در نرم افزار ثبت نمود.",
  },
  {
    featureIcon: People,
    featureTitle: "انتقال مصالح بین پیمانکاران",
    featureDescription:
      "به منظور انتقال کالا و مصالح بین پیمانکاران یک سازمان میتوان از طریق فرم های انتقال بین پیمانکاران و قراردادهای یک سازمان اقدام نموده و سابقه آن را در نرم افزار نگهداری نمود.",
  },
  {
    featureIcon: Arrow,
    featureTitle: "انتقال مصالح بین انبارها",
    featureDescription:
      "یکی از رخدادهای متداول در یک سازمان که انبارهای متعددی دارد، انتقال کالا و مصالح بین این انبارهاست که میتوان از طریق مپسان عملکرد انتقال را ثبت و رصد نمود.",
  },
  {
    featureIcon: Bricks,
    featureTitle: "ثبت مصرفی کالا و مصالح پروژه",
    featureDescription:
      "به منظور دریافت گزارشات موجودی واقعی کالا و مصالح در پروژه ها میتوان مقادیر مصرفی را از طریق فرم های ریز متره ثبت و گزارشات موجودی مصالح را دریافت نمود.",
  },
  {
    featureIcon: Boxes,
    featureTitle: "تایید فنی و کنترل کیفی مصالح",
    featureDescription:
      "به منظور جلوگیری استفاده از مصالح نامرغوب و غیر استاندارد در پروژه ها، امکان ثبت تایید فنی و کنترل کیفی در نرم افزار مدیریت پروژه مپسان فراهم آمده است.",
  },
  {
    featureIcon: Flag,
    featureTitle: "تسویه حساب کالا کارفرما و پیمانکار",
    featureDescription:
      "از جمله فرآیندهای پیچیده و زمانبر شرکت های کارفرما، تسویه حساب کالا و مصالح پیمانکاران در انتهای پروژه است که به کمک مپسان این امر به راحتی قابل انجام است.",
    },
];

export default class MaterialCyclesFeaturesComponent extends Component {
  render = () => (
    <section className="plannig-features-section mt-5 mb-5 ">
              <h2 className="feature-section-title text-center planning-section-title">امکانات گردش کالا و مصالح پروژه</h2>
      <section className="custom-container">
              <div className="row planning-features-container mt-5">
                {featureList.map((feature, index) => {
                  return (
                    <AnimationOnScroll animateIn="animate__zoomIn" className="col-md-4 p-2">
                  <div className="planning-item p-2">
                    <section className="mobile-feature-header">
                      <span className="planning-item-title">{feature.featureTitle}</span>
                      <img src={feature.featureIcon} className="mobile-feature-icon m-2" alt="Gantticon" width="30" height="30"></img>
                    </section>
                    <hr />
                    <p className="planning-feature-desc">
                      {feature.featureDescription}
                    </p>
                  </div>
              </AnimationOnScroll>
            );
          })}
          </div>
      </section>
    </section>
  );
}