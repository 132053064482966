import React, { Component } from "react";
import "../styles/DropDownNavbar.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

class DropDownItem extends Component {
  render = () => {
    const childNodes =
      this.props.childs && this.props.childs.length > 0 ? (
        <div className={`dropdown-item-${this.props.level}-child`}>
          <div className={`dropdown-item-${this.props.level}-child-box`}>
            {this.props.childs.map((item, index) => {
              return (
                <DropDownItem
                  key={index}
                  link={this.props.link + item.link}
                  linkText={item.linkText}
                  childs={item.childs}
                  level={this.props.level + 1}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div></div>
      );
    
    const angleRight = 
      this.props.childs ? (
        <FontAwesomeIcon
          icon={faAngleRight}
          className="dropdown-item-arrow-icon"
        />
      ) : (
        ""
      );

    return (
      <div className={`dropdown-item-${this.props.level} dropdown-item-highlight`}>
        <div className="dropdown-item-arrow">
          {angleRight}
        </div>
        <a className="dropdown-item-link" href={this.props.link} target="_blank">
          {this.props.linkText}
        </a>
        {childNodes}
      </div>
    );
  };
}

export default DropDownItem;
