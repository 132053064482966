import React, { Component } from "react";
import ShapeSmall from "../resources/images/shape-tiny.svg";
import ShapeMedium from "../resources/images/shape-medium.svg";
import DarkBlueContractors from "../resources/images/blue-dark-contractors.svg";
import DarkBlueContracts from "../resources/images/blue-dark-contracts.svg";
import DarkBlueAuctions from "../resources/images/blue-dark-auctions.svg";
import DarkBlueInvoices from "../resources/images/blue-dark-invoices.svg";
import DarkBluePayments from "../resources/images/blue-dark-payments.svg";
import DarkBlueGarantee from "../resources/images/blue-dark-garantee.svg";
import DarkBlueDocuments from "../resources/images/blue-dark-documents.svg";
import LightBlueZarayeb from "../resources/images/blue-light-zarayeb.svg";
import LightBlueFaharesebaha from "../resources/images/blue-light-faharesebaha.svg";
import LightBlueBaravord from "../resources/images/blue-light-baravord.svg";
import LightBlueAzadsazi from "../resources/images/blue-light-azadsazi.svg";
import LightBlueTahvileghatei from "../resources/images/blue-light-tahvilghatei.svg";
import LightBlueTahvilemovaghat from "../resources/images/blue-light-tahvilmovaghat.svg";
import LightBlueElhaghiyeha from "../resources/images/blue-light-elhaghiyehayeha.svg";
import LightBlueGharardadReport from "../resources/images/blue-light-gharardadreport.svg";
import LightBlueGorohayePeymankaran from "../resources/images/blue-light-gorohbandipeymankaran.svg";
import LightBlueGorohayeGharardad from "../resources/images/blue-light-gorohbandigharardad.svg";
import "../styles/Contracts.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default class ContractsFeaturesComponent extends Component {
  render = () => (
    <section className="contract-feature-section mb-5">
        <section className="row contract-feature-container mt-5">
          <div className="col contract-columns contract-col-1">
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-light">
              <img src={LightBlueAzadsazi} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-dark">
              <img src={DarkBluePayments} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-light">
              <img src={LightBlueBaravord} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
          </div>
          <div className="col contract-columns contract-col-2">
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-light">
              <img src={LightBlueElhaghiyeha} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-dark">
              <img src={DarkBlueInvoices} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-dark">
              <img src={DarkBlueDocuments} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-light">
              <img src={LightBlueGharardadReport} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
          </div>
          <div className="col contract-columns contract-col-3">
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-light">
              <img src={LightBlueGorohayePeymankaran} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-dark">
              <img src={DarkBlueAuctions} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
             <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-light">
              <img src={LightBlueGorohayeGharardad} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
          </div>
          <div className="col contract-columns contract-col-4">
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-light">
              <img src={LightBlueTahvileghatei} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-dark">
              <img src={DarkBlueContracts} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-dark">
              <img src={DarkBlueGarantee} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-light">
              <img src={LightBlueTahvilemovaghat} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
          </div>
          <div className="col contract-columns contract-col-5">
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-light">
              <img src={LightBlueZarayeb} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-dark">
              <img src={DarkBlueContractors} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__zoomIn">
            <div className="contract-poly-light">
              <img src={LightBlueFaharesebaha} alt="" className="dark-blue-poly"/>
            </div>
            </AnimationOnScroll>
          </div>
        </section>
    </section>
  );
}