import React, { Component } from "react";
import Clipboard from '../resources/images/clipboard-list.svg';
import Shield from '../resources/images/shield.svg';
import Digging from '../resources/images/person-digging.svg';
import Image from '../resources/images/image.svg';
import Print from '../resources/images/print.svg';
import Codebranch from '../resources/images/code-branch.svg';
import NFC from '../resources/images/microscope.svg';
import Mobile from '../resources/images/mobile-button.svg';
import Clone from '../resources/images/clone.svg';
import "../styles/PlanningFeatures.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const featureList = [
  {
    featureIcon: Clipboard,
    featureTitle: "فرم های نظارت کارگاهی",
    featureDescription:
      "استفاده از امکانات فرم ساز نرم افزار مدیریت پروژه مپسان میتوانید انواع الگوهای نظارت کارگاهی را تعریف کرده و در صورت نیاز به راحتی از آن ها برای ایجاد فرم ها استفاده نمائید.",
  },
  {
    featureIcon: Shield,
    featureTitle: "فرم های HSE",
    featureDescription:
    " به منظور کنترل کیفی و ایمنی پروژه امکان ایجاد الگوهای فرم های کنترل کیفی و ایمنی پروژه فراهم آمده که به این وسیله میتوانید با ثبت و ضبط این فرم ها از بروز حوادث تا حد امکان پیشگیری به عمل آورید."
  },
  {
    featureIcon: Digging,
    featureTitle: "ثبت مصالح و عملیات اجرائی",
    featureDescription:
      "ثبت مقادیر مصرفی کالا و مصالح در حین اجرای پروژه و همچنینی عملیات اجرائی از طریف فرم نظارت کارگاهی امکان پذیر است که از اطلاعات این فرم ها به منظور صدور صورت وضعیت و تسویه حساب پیمانکار نیز میتوانید استفاده کنید.",
  },
  {
    featureIcon: Image,
    featureTitle: "ضمیمه تصاویر و مستندات",
    featureDescription:
      "ثبت و نگهداری تصاویر و مستندات پروژه بصورت متمرکز و آنلاین با امکان دسترسی آسان و سریع به آن ها از جمله مزایای استفاده از نرم افزار مدیریت پروژه آنلاین مپسان می باشد.",
  },
  {
    featureIcon: Print,
    featureTitle: "چاپ فرم های نظارتی",
    featureDescription:
      "به منظور بایگانی نسخ فیزیکی فرم های نظارت کارگاهی و یا فرم های کنترل کیفی و ایمنی پروژه به همراه امضای فیزیکی ناظرین امکان چاپ فرم نظارتی از طریق گزارش ساز مپسان ایجاد شده است.",
  },
  {
    featureIcon: Codebranch,
    featureTitle: "گردش کاری فرم های نظارتی",
    featureDescription:
      "درصورت تمایل مدیران سازمان و یا پروژه ها میتوان به منظور نظارت عالیه مسئولین، گردش کاری به منظور بررسی و تایید فرم های نظارت کارگاهی و کنترل کیفی و ایمنی پروژه ایجاد کرد.",
  },
  {
    featureIcon: NFC,
    featureTitle: "کنترل محاسباتی احجام اجرائی",
    featureDescription:
      "این امکان در فرم های نظارت کارگاهی مپسان وجود دارد که مقادیر کالا و مصالح مصرفی و عملیات اجرائی مربوط به همان مصالح محاسبه و بررسی گردد که آیا با یکدیگر مغایرت دارند یا خیر ",
  },
  {
    featureIcon: Clone,
    featureTitle: "کنترل تکراری اجرائیات",
    featureDescription:
      "با هدف جلوگیری از پرداخت حق الزحمه برای عملیات اجرائی تکراری اعلامی توسط پیمانکار در انواع پروژه ها، امکانی فراهم آمده که درصورت اعلام اجرائیات تکراری نرم افزار آلارم داده و اجازه پرداخت نخواهد داد.",
    },
    {
      featureIcon: Mobile,
      featureTitle: "ثبت فرم ها از طریق گوشی هوشمند",
      featureDescription:
        "با هدف سهولت دسترسی ناظرین پروژه ها به فرم ها و الگوهای نظارتی و کنترل کیفی و ایمنی پروژه ها، اپلیکیشن موبایل مپسان با امکان ایجاد و ثبت اطلاعات انواع فرم های نظارتی توسعه داده شده است.",
    },
];

export default class SupervisionFeaturesComponent extends Component {
  render = () => (
    <section className="plannig-features-section mt-5 mb-5 ">
              <h2 className="feature-section-title text-center planning-section-title">امکانات نظارت بر اجرای پروژه های مپسان</h2>
      <section className="custom-container">
              <div className="row planning-features-container mt-5">
                {featureList.map((feature, index) => {
                  return (
                    <AnimationOnScroll animateIn="animate__zoomIn" className="col-md-4 col-sm-6 p-2">
                  <div className="planning-item p-2">
                    <section className="mobile-feature-header">
                      <span className="planning-item-title">{feature.featureTitle}</span>
                      <img src={feature.featureIcon} className="mobile-feature-icon m-2" alt="Gantticon" width="30" height="30"></img>
                    </section>
                    <hr />
                    <p className="planning-feature-desc">
                      {feature.featureDescription}
                    </p>
                  </div>
              </AnimationOnScroll>
            );
          })}
          </div>
      </section>
    </section>
  );
}