import React, { Component } from "react";
import "../styles/DropDownNavbar.css";
import DropDownItem from "./DropDownItem";

class DropDownNavbarComponent extends Component {
  render = () => {
    return (
      <div className="dropdown-item-0-child">
        <div className="dropdown-item-0-child-box"> { /* dropdown-content */ }
          {this.props.relatedLinks.map((item, index) => {
            return (
              <DropDownItem
                key={index}
                link={this.props.link + item.link}
                linkText={item.linkText}
                childs={item.childs}
                level={1}
              />
            );
          })}
        </div>
      </div>
    );
  };
}

export default DropDownNavbarComponent;
