import React, { Component } from 'react';
import '../styles/PlanningIntro.css';

export default class PlanningIntroComponent extends Component
{
    render = () =>
        <section className="planning-intro">
            <div className="row custom-container">
                <div className="col-12 d-flex justify-content-around align-items-center section-container">
                    <h2 className="section-title">برنامه ریزی و کنترل پروژه</h2>
                </div>
            </div>
        </section>;
}
