import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "../styles/Sidebar.css";

class SidebarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarStatus: false,
    };
  }

  render = () => (
    <div>
      <button className="btn btn-sidebar-toggler" onClick={this.toggleVariable}>
        <FontAwesomeIcon
          icon={this.state.sidebarStatus ? faWindowClose : faGripLines}
          color="#ffffff"
        />
      </button>
      <aside className="d-none" id="sidebar">
        <li className="slidebar-item">
          <Link to={"/"} className="slidebar-link">
            خانه
          </Link>
        </li>
        <li className="slidebar-item">
          <a href="/posts" className="slidebar-link">
            نوشته ها
          </a>
        </li>
        {/* <li className="slidebar-item">
                    <a href="/user-manual" className="slidebar-link">امکانات</a>
                </li> */}
        <li className="slidebar-item">
          <a href="/training" className="slidebar-link">
            مرکز آموزش
          </a>
        </li>
        <li className="slidebar-item">
          <a href="/subsystems" className="slidebar-link">
            سیستم ها
          </a>
        </li>
        <li className="slidebar-item">
          <a href="/pricelist" className="slidebar-link">
            لیست قیمت
          </a>
        </li>
      </aside>
    </div>
  );

  toggleVariable = () => {
    this.setState({
      sidebarStatus: !this.state.sidebarStatus,
    });
    let sidebar = document.getElementById("sidebar");
    this.state.sidebarStatus
      ? sidebar.classList.add("d-none")
      : sidebar.classList.remove("d-none");
  };
}

export default SidebarComponent;
