import React, { Component } from 'react';
import '../styles/HomeHeader.css';
import HeaderInfographic from './HeaderInfographic';
import LogoAndNavbarComponent from './LogoAndNavbarComponent';

class HeaderComponent extends Component
{
    render = () => 
        <header className="container-fluid" id="home-header">
            <div className="custom-container">
                <LogoAndNavbarComponent />
                <HeaderInfographic />
            </div>
        </header>;
}

export default HeaderComponent;
