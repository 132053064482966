import React, { Component } from "react";
import Rectangular from '../resources/images/rectangle-history-circle-plus.svg';
import CodeFork from '../resources/images/code-fork.svg';
import DiagramSubtask from '../resources/images/diagram-subtask.svg';
import People from '../resources/images/people-group.svg';
import Star from '../resources/images/star.svg';
import UserGroup from '../resources/images/user-group-crown.svg';
import Wand from '../resources/images/wand-magic-sparkles.svg';
import CheckDouble from '../resources/images/check-double.svg';
import Folder from '../resources/images/folder-user.svg';
import "../styles/PlanningFeatures.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const featureList = [
  {
    featureIcon: Rectangular,
    featureTitle: "تعریف گردش کار انواع فرم",
    featureDescription:
      "به منظور گردش کار انواع فرم از فرم های نرم افزار مدیریت پروژه مپسان ار جمله فرم های متره و برآورد،  فرم نظارت کارگاهی، صورت وضعیت، سند حسابداری و ....",
  },
  {
    featureIcon: DiagramSubtask,
    featureTitle: "تعداد مراحل دلخواه",
    featureDescription:
    "بدون محدودیت تعداد مراحل دلخواه خود را بر اساس فرآیند های کاری سازمانتان تعریف کرده و فرم ها را در این مراحل به گردش دربیاورید."
  },
  {
    featureIcon: CodeFork,
    featureTitle: "تعریف نقش های موازی",
    featureDescription:
      "به منظور بررسی یا تایید یک مرحله از مراحل گردش کار میتوانید 2 یا چند نقش راانتخاب کرده تا مرحله مورد نظر بصورت موازی توسط آن ها تکمیل پردد.",
  },
  {
    featureIcon: CheckDouble,
    featureTitle: "تایید توسط یک یا چند نقش",
    featureDescription:
      "هر مرحله از گردش کار را میتوان به نحوی تنظیم کرد که با تایید یک نقش یا همه نقش های انتخاب شده برای آن مرحله، فرم تایید یا تکمیل شود.",
  },
  {
    featureIcon: Star,
    featureTitle: "تعیین نقش پیش فرض",
    featureDescription:
      "برای هر مرحله میتوان نقش یا نقش هائی را بصورت پیش فرض تعیین کرد تا در مراحل گردش کار بصورت اتوماتیک قرار داده شوند.",
  },
  {
    featureIcon: People,
    featureTitle: "تعریف لیست متصدیان مجاز",
    featureDescription:
      "به منظور محدود سازی انتخاب های ارجاع برای یک مرحله میتوان لیستی از متصدیان مجاز برای آن مرحله تعریف نمود.",
  },
  {
    featureIcon: UserGroup,
    featureTitle: "تفویض اختیار کارتابل",
    featureDescription:
      "ریز زمان صرف شده بر روی فعالیت ها و فازهای پروژه از طریق بخش گزارشات در دست مدیران پروژه و شرکت شما قرار خواهد گرفت",
  },
  {
    featureIcon: Wand,
    featureTitle: "تایید و خروج تایید اتوماتیک",
    featureDescription:
      "تنظیمات گردش های کاری را میتوان به نحوی انحام داد که با ارجاع یک فرم به مرحله بعد فرم مذکور تایید شده یا با عودت آن، از تایید خارج شود.",
  },
  {
    featureIcon: Folder,
    featureTitle: "کارتابل وظایف گردش کار",
    featureDescription:
      " کارتابل وظایف اختصاصی هر کاربر مجزا از کارتابل فعالیت های واگذار شده پروژه در دسترس کاربران خواهد بود تا وظایف خود را به تفکیک مشاهده نمایند.",
    },
];

export default class WorkflowFeaturesComponent extends Component {
  render = () => (
    <section className="plannig-features-section mt-5 mb-5 ">
              <h2 className="feature-section-title text-center planning-section-title">امکانات گردش های کاری مپسان</h2>
      <section className="custom-container">
              <div className="row planning-features-container mt-5">
                {featureList.map((feature, index) => {
                  return (
                    <AnimationOnScroll animateIn="animate__zoomIn" className="col-md-4 p-2">
                  <div className="planning-item p-2">
                    <section className="mobile-feature-header">
                      <span className="planning-item-title">{feature.featureTitle}</span>
                      <img src={feature.featureIcon} className="mobile-feature-icon m-2" alt="Gantticon" width="30" height="30"></img>
                    </section>
                    <hr />
                    <p className="planning-feature-desc">
                      {feature.featureDescription}
                    </p>
                  </div>
              </AnimationOnScroll>
            );
          })}
          </div>
      </section>
    </section>
  );
}