import React, { Component } from "react";
import MagnifyingGlassChart from '../resources/images/magnifying-glass-chart.svg';
import StopWatch from '../resources/images/stopwatch.svg';
import Image from '../resources/images/image.svg';
import MagnifyingGlassLocation from '../resources/images/magnifying-glass-location.svg';
import ArrowDots from '../resources/images/arrow-up-right-dots.svg';
import DollarSign from '../resources/images/dollar-sign.svg';
import Cloud from '../resources/images/cloud.svg';
import PieChart from '../resources/images/chart-pie.svg';
import Binoculars from '../resources/images/binoculars.svg';
import Heart from '../resources/images/heart.svg';
import "../styles/PlanningFeatures.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const featureList = [
  {
    featureIcon: MagnifyingGlassLocation,
    featureTitle: "کنترل حضور پرسنل پروژه",
    featureDescription:
      "ثبت موقعیت مکانی هنگام درج تایم شیت توسط پرسنل پروژه این امکان را به مدیران می دهد تا از زمان دقیق ورود پرسنل پروژه به محل پروژه یا دفتر مرکزی اطلاع پیدا کنند.",
  },
  {
    featureIcon: StopWatch,
    featureTitle: "زمان صرف شده هر فعالیت پروژه",
    featureDescription:
    "ارتباط میان ماژول تایم شیت و ماژول برنامه ریزی این امکان را به شما می دهد تا زمان زمان صرف شده برای هر فعالیت و مجموع پروژه را در دست داشته باشید."
  },
  {
    featureIcon: Binoculars,
    featureTitle: "شفافیت عملکرد پرسنل شرکت",
    featureDescription:
      "با ثبت زمان صرف شده برای هر فعالیت توسط پرسنل شرکت یا پروژه میتوان عملکرد پرسنل را مورد ارزیابی قرار داد.",
  },
  {
    featureIcon: DollarSign,
    featureTitle: "سهولت محاسبه حقوق پرسنل",
    featureDescription:
      "واحد مالی و حسابداری شرکت با در درست داشتن زمان واقعی صرف شده توسط پرسنل شرکت یا پروژه ها مدت زمان کمتری را صرف محاسبه حقوق و دستمزد پرسنل خواهند کرد.",
  },
  {
    featureIcon: ArrowDots,
    featureTitle: "تمرکز بر روی افزایش بهره وری",
    featureDescription:
      "پرسنل شرکت می توانند به جای صرف زمان بر روی فعالیت های زیان ده، تمرکز خود را بر روی فعالیت هائی که برای شرکت ارزش ایجاد خواهد کرد بگذارند و اینگونه بهره وری را افزایش دهند.",
  },
  {
    featureIcon: Cloud,
    featureTitle: "دسترسی در هر زمان و مکان",
    featureDescription:
      "با استفاده از اپلیکیشن گوشی هوشمند کاربران می توانند بدون محدودیت زمانی و مکانی گزارش ساعت کاری خود را ارسال نمایند.",
  },
  {
    featureIcon: Heart,
    featureTitle: "کاربر پسند و آسان",
    featureDescription:
      "محیط نرم افزار تایم شیت مپسان به گونه ای طراحی شده که به راحتی پرسنل شما گزارشات عملکرد ساعت های صرف شده فعالیت ها را ثبت کنند.",
  },
  {
    featureIcon: MagnifyingGlassChart,
    featureTitle: "گزارشات کارکرد پرسنل",
    featureDescription:
      "مدیران شرکت و مسئولین واحد های مربوطه گزارشات کارکرد پرسنل را از بخش گزارشات تایم شیت نرم افزار مدیریت پروژه مپسان بصورت آنلاین مشاهده خواهند کرد.",
    },
    {
      featureIcon: PieChart,
      featureTitle: "گزارش به تفکیک فازهای پروژه",
      featureDescription:
        "ریز زمان صرف شده بر روی فعالیت ها و فازهای پروژه از طریق بخش گزارشات در دست مدیران پروژه و شرکت شما قرار خواهد گرفت",
    },
];

export default class TimesheetFeaturesComponent extends Component {
  render = () => (
    <section className="plannig-features-section mt-5 mb-5 ">
              <h2 className="feature-section-title text-center planning-section-title">امکانات تایم شیت مپسان</h2>
      <section className="custom-container">
              <div className="row planning-features-container mt-5">
                {featureList.map((feature, index) => {
                  return (
                    <AnimationOnScroll animateIn="animate__zoomIn" className="col-md-4 p-2">
                  <div className="planning-item p-2">
                    <section className="mobile-feature-header">
                      <span className="planning-item-title">{feature.featureTitle}</span>
                      <img src={feature.featureIcon} className="mobile-feature-icon m-2" alt="Gantticon" width="30" height="30"></img>
                    </section>
                    <hr />
                    <p className="planning-feature-desc">
                      {feature.featureDescription}
                    </p>
                  </div>
              </AnimationOnScroll>
            );
          })}
          </div>
      </section>
    </section>
  );
}