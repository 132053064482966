import React, { Component } from "react";
import one from '../resources/images/1.svg';
import two from '../resources/images/2.svg';
import three from '../resources/images/3.svg';
import four from '../resources/images/4.svg';
import five from '../resources/images/5.svg';
import "../styles/WorkflowMaterialRequest.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default class WorkflowMaterialRequestComponent extends Component {
  render = () => (
    <section className="workflow-example-section mt-5 mb-5 ">
              <h2 className="feature-section-title text-center planning-section-title">نمونه گردش کار خرید و تدارکات پروژه</h2>
      <section className="custom-container d-flex flex-column justify-content-center">
        <div className="row">
               <div className="col d-flex flex-column justify-content-start workflow-column p-3">
                <AnimationOnScroll animateIn="animate__fadeInDown">
                <img src={five} alt="five-image" className="workflow-images image-five" />
                <h2>واحد تدارکات</h2>
                <p>تمامی درخواست های از این نوع مصالح را مشاهده و برای نحوه خرید آن تصمیم گیری می کند.</p>
                </AnimationOnScroll>
              </div>
              <div className="col d-flex flex-column justify-content-center workflow-column p-3">
                <AnimationOnScroll animateIn="animate__fadeInUp">
                <h2>واحد مالی</h2>
                <p> در این مرحله بودجه مورد نیاز با توجه به سابقه مالی کارفرما بررسی و تامین می شود.</p>
                <img src={four} alt="four-image" className="workflow-images image-four" />
                </AnimationOnScroll>
              </div>
              <div className="col d-flex flex-column justify-content-end workflow-column p-3">
                <AnimationOnScroll animateIn="animate__fadeInDown">
                <img src={three} alt="three-image" className="workflow-images image-three" />
                <h2>واحد برآورد</h2>
                <p>بررسی می شود که آیا مقدار مصالح درخواستی متناسب با میزان برآورد پروژه است یا خیر.</p>
                </AnimationOnScroll>
              </div>
              <div className="col d-flex flex-column justify-content-center workflow-column p-3">
                <AnimationOnScroll animateIn="animate__fadeInUp">
                <h2>مسئول انبار</h2>
                <p>موجودی کالای درخواستی در انبار بررسی شده و درصورت عدم موجودی به مرحله بعد ارجاع می دهد. </p>
                <img src={two} alt="two-image" className="workflow-images image-two" />
                </AnimationOnScroll>
              </div>
              <div className="col d-flex flex-column justify-content-end workflow-column p-3">
                <AnimationOnScroll animateIn="animate__fadeInDown">
                <img src={one} alt="one-image" className="workflow-images image-one" />
                <h2>درخواست خرید</h2>
                <p>ابتدا کاربر از طریق ماژول تدارکات پروژه یک درخواست خرید مصالح و تجهیزات ایجاد می کند.</p>
                </AnimationOnScroll>
              </div>
              {/* <div className="col-md-2 d-flex flex-column justify-content-start">
                <AnimationOnScroll animateIn="animate__fadeInUp" delay={3}>
                <h2>06</h2>
                <h2>مسئول واحد خرید</h2>
                <p> مسئول واحد خرید با مشاهده وظیفه واگذار شده به وی در کارتابلش اقدام به خرید مصالح و پیگیری تحویل آن به انبار می شود.</p>
                </AnimationOnScroll>
              </div> */}
        </div>
      </section>
    </section>
  );
}