import React, { Component } from "react";
import CostMangement01 from "../resources/images/1-01.png";
import CostMangement02 from "../resources/images/2-01.png";
import CostMangement03 from "../resources/images/3-01.png";
import CostMangement04 from "../resources/images/4-01.png";
import CostMangement05 from "../resources/images/5-01.png";
import CostMangement06 from "../resources/images/6-01.png";
import CostMangement07 from "../resources/images/7-01.png";
import CostMangement08 from "../resources/images/8-01.png";
import CostMangement09 from "../resources/images/9-01.png";
import CostMangement10 from "../resources/images/10-01.png";
import "../styles/CostManagement.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default class CostManagementFeaturesComponent extends Component {
  render = () => (
    <section className="cost-feature-section mb-5">
        <section className="row cost-feature-container mt-5">
          <div className="col-md-1 cost-column cost-column-01">
          <AnimationOnScroll animateIn="animate__zoomIn">
            <img src={CostMangement01} alt=""/>
          </AnimationOnScroll>
          </div>
          <div className="col-md-1 cost-column cost-column-02">
          <AnimationOnScroll animateIn="animate__zoomIn">
            <img src={CostMangement02} alt="" className="cost-puzzle-piece"/>
            <p>کنترل بودجه بر اساس سرفصل های حسابداری</p>
          </AnimationOnScroll>
          </div>
          <div className="col-md-1 cost-column cost-column-03">
          <AnimationOnScroll animateIn="animate__zoomIn">
            <p>ثبت فاکتورهای خرید مصالح و هزینه های پروژه</p>
            <img src={CostMangement03} alt="" className="cost-puzzle-piece"/>
          </AnimationOnScroll>
          </div>
          <div className="col-md-1 cost-column cost-column-04">
          <AnimationOnScroll animateIn="animate__zoomIn">
            <img src={CostMangement04} alt="" className="cost-puzzle-piece"/>
            <p>کنترل بودجه بر اساس فازهای پروژه</p>
          </AnimationOnScroll>
          </div>
          <div className="col-md-1 cost-column cost-column-05">
          <AnimationOnScroll animateIn="animate__zoomIn">
            <p>گردش سرفصل های حسابداری مرتبط با پروژه</p>
            <img src={CostMangement05} alt="" className="cost-puzzle-piece"/>
          </AnimationOnScroll>
          </div>
          <div className="col-md-1 cost-column cost-column-06">
          <AnimationOnScroll animateIn="animate__zoomIn">
            <img src={CostMangement06} alt="" className="cost-puzzle-piece"/>
            <p>محاسبه بودجه بر اساس متره و برآورد</p>
          </AnimationOnScroll>
          </div>
          <div className="col-md-1 cost-column cost-column-07">
          <AnimationOnScroll animateIn="animate__zoomIn">
            <p>کنترل تنخواه سرپرست کارگاه پروژه</p>
            <img src={CostMangement07} alt="" className="cost-puzzle-piece"/>
          </AnimationOnScroll>
          </div>
          <div className="col-md-1 cost-column cost-column-08">
          <AnimationOnScroll animateIn="animate__zoomIn">
            <img src={CostMangement08} alt="" className="cost-puzzle-piece"/>
            <p>بیس لاین های مختلف از بودجه  پروژه ها</p>
          </AnimationOnScroll>
          </div>
          <div className="col-md-1 cost-column cost-column-09">
          <AnimationOnScroll animateIn="animate__zoomIn">
            <p>نمودار پیشرفت و انحرافات ریالی پروژه</p>
            <img src={CostMangement09} alt="" className="cost-puzzle-piece"/>
          </AnimationOnScroll>
          </div>
          <div className="col-md-1 cost-column cost-column-10">
          <AnimationOnScroll animateIn="animate__zoomIn">
            <img src={CostMangement10} alt=""/>
          </AnimationOnScroll>
          </div>
        </section>
    </section>
  );
}