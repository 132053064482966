import React, { Component } from "react";
import ChartGantt from '../resources/images/chart-gantt.svg';
import CartShopping from '../resources/images/cart-shopping-fast.svg';
import ChartPie from '../resources/images/chart-pie.svg';
import Dolly from '../resources/images/dolly.svg';
import CodeCompare from '../resources/images/code-compare.svg';
import HandShake from '../resources/images/handshake.svg';
import NfcMagnify from '../resources/images/nfc-magnifying-glass.svg';
import Compass from '../resources/images/compass-drafting.svg';
import Timer from '../resources/images/timer.svg';
import Users from '../resources/images/users-line.svg';
import Money from '../resources/images/money-bill-transfer.svg';
import OrderList from '../resources/images/list-ol.svg';
import "../styles/Component.css";
import "animate.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const featureList = [
  {
    featureIcon: ChartGantt,
    featureTitle: "برنامه ریزی و کنترل پروژه",
    featureDescription:
      "",
  },
  {
    featureIcon: NfcMagnify,
    featureTitle: "نظارت بر اجرای پروژه",
    featureDescription:
      "",
  },
  {
    featureIcon: CartShopping,
    featureTitle: "خرید و تدارکات پروژه",
    featureDescription:
      "",
  },
  {
    featureIcon: Compass,
    featureTitle: "متره و برآورد پروژه",
    featureDescription:
      "",
    },
    {
      featureIcon: Dolly,
      featureTitle: "گردش کالا و مصالح پروژه",
      featureDescription:
        "",
    },
    {
      featureIcon: HandShake,
      featureTitle: "قراردادها و پیمانکاران",
      featureDescription:
        "",
    },
  {
    featureIcon: Timer,
    featureTitle: "تایم شیت تیم پروژه",
    featureDescription:
      "",
  },
  {
    featureIcon: Users,
    featureTitle: "مدیریت جلسات پروژه",
    featureDescription:
      "",
  },
  {
    featureIcon: Money,
    featureTitle: "مدیریت هزینه/کنترل بودجه",
    featureDescription:
      "",
  },
  {
    featureIcon: OrderList,
    featureTitle: "فرم ساز مپسان",
    featureDescription:
      "",
  },
  {
    featureIcon: CodeCompare,
    featureTitle: "گردش های کاری فرآیندها",
    featureDescription:
      "",
  },
  {
    featureIcon: ChartPie,
    featureTitle: "داشبورد پروژه ها",
    featureDescription:
      "",
  },
];

export default class ComponentsComponent extends Component {
  render = () => (
    <section className="custom-container my-5 components">
      <div className="row no-gutters">
        <div className="col-12 text-center">
            <h2 className="feature-section-title">امکانات مپسان</h2>
        </div>
      </div>
      <div className="row mt-4 mb-5 justify-content-center no-gutters">
        {featureList.map((feature, index) => {
          return (
            <div className="col-6 col-lg-3 col-md-4 col-sm-6 my-2 component-container">
              <AnimationOnScroll animateIn="animate__flipInX" duration="2">
              <div className="component-item">
                <img src={feature.featureIcon} className="feature-icon mt-4" alt="Gantticon" width="50" height="50"></img>
                <br />
                  <p className="component-item-title">
                  {feature.featureTitle}  
                  </p>
                <br />
              </div>
              </AnimationOnScroll>
            </div>
          );
        })}
      </div>
    </section>
  );
}
