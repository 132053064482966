import React, { Component } from "react";
import ComponentsComponent from "../components/ComponentsComponent";
import MobileIntroComponent from "../components/MobileIntroComponent";
import MobileFeaturesComponent from "../components/MobileFeaturesComponent";
import FooterComponent from "../components/FooterComponent";
import HeaderComponent from "../components/HeaderComponent";
import PlanningIntroComponent from "../components/PlanningIntroComponent";
import PlanningFeaturesComponent from "../components/PlanningFeaturesComponent";
import PlanningProcessComponent from "../components/PlanningProcessComponent";
import TimesheetIntroComponent from "../components/TimesheetIntroComponent";
import TimesheetFeaturesComponent from "../components/TimesheetFeaturesComponent";
import WorkflowIntroComponent from "../components/WorkflowIntroComponent";
import WorkflowFeaturesComponent from "../components/WorkflowFeaturesComponent";
import FormCreatorIntroComponent from "../components/FormCreatorIntroComponent";
import FormCreatorFeaturesComponent from "../components/FormCreatorFeaturesComponent";
import MaterialCyclesIntro from "../components/MaterialCyclesIntro";
import MaterialCyclesFeaturesComponent from "../components/MaterialCyclesFeaturesComponent";
import MandeKarfarmaComponent from "../components/MandeKarfarmaComponent";
import ProcurementIntro from "../components/ProcurementIntro";
import ProcurementProcessComponent from "../components/ProcurementProcessComponent";
import EstimateIntro from "../components/EstimateIntroComponent";
import EstimateFeaturesComponent from "../components/EstimateFeaturesComponent";
import SupervisionIntroComponent from "../components/SupervisionIntroComponent";
import SupervisionFeaturesComponent from "../components/SupervisionFeaturesComponent";
import ContractsIntroComponent from "../components/ContractsIntroComponent";
import ContractsFeaturesComponent from "../components/ContractsFeaturesComponent";
import CostManagementIntroComponent from '../components/CostManagementIntroComponent';
import CostManagementFeaturesComponent from '../components/CostManagementFeaturesComponent';
import DashboardIntroComponent from '../components/DashboardIntroComponent';
import DashboardComponent from '../components/DashboardComponent';
import CarouselComponent from "../components/CarouselComponent";
import WorkflowMaterialRequestComponent from "../components/WorkflowMaterialRequestComponent";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Home extends Component {
  render = () => (
    <React.Fragment>
      <HeaderComponent />
      <ComponentsComponent />
      {/* <PlayerComponent /> */}
      <MobileIntroComponent />
      <MobileFeaturesComponent />
      <PlanningIntroComponent />
      <PlanningFeaturesComponent />
      <PlanningProcessComponent />
      <TimesheetIntroComponent />
      <TimesheetFeaturesComponent />
      <WorkflowIntroComponent />
      <WorkflowFeaturesComponent />
      <WorkflowMaterialRequestComponent />
      <FormCreatorIntroComponent />
      <FormCreatorFeaturesComponent />
      <MaterialCyclesIntro />
      <MaterialCyclesFeaturesComponent />
      <MandeKarfarmaComponent />
      <ProcurementIntro />
      <ProcurementProcessComponent />
      <EstimateIntro />
      <EstimateFeaturesComponent />
      <SupervisionIntroComponent />
      <SupervisionFeaturesComponent />
      <ContractsIntroComponent />
      <ContractsFeaturesComponent />
      <CostManagementIntroComponent />
      <CostManagementFeaturesComponent />
      <DashboardIntroComponent />
      <DashboardComponent />
      <CarouselComponent />
      <FooterComponent />
    </React.Fragment>
  );
}

export default Home;
