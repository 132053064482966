import React, { Component } from "react";
import "../styles/HeaderInfographic.css";
import SlideCruze from "../resources/images/slide-cruz.svg";

class HeaderInfographic extends Component {
  render = () => (
    <div className="row">
      <div className="introduce-col">
        <h2 className="header-title">مپسان </h2>
        <h1 className="header-info">نرم افزار مدیریت پروژه آنلاین</h1>
        <p className="header-text"></p>
        <div className="header-buttons">
          <a href="/demorequest" className="btn btn-blue btn-large">
            درخواست جلسه دمو
          </a>
          <a
            href="https://mapsunapp.ir/auth/login"
            target="_blank"
            rel="noopener noreferrer nofollow"
            className="btn btn-outlined btn-large"
          >
            ورود
          </a>
        </div>
      </div>
    </div>
  );
}

export default HeaderInfographic;
