import React, { Component } from 'react';
import '../styles/FormCreatorFeatures.css';
import Database from '../resources/images/database.svg';
import Formsaz from '../resources/images/formsaz.svg';
import Workflow from '../resources/images/workflow.svg';


export default class FormCreatorFeaturesComponent extends Component
{
    render = () =>
        <section className='custom-container align-items-center text-center mt-5 mb-5'>
            <h2>مجموعه کامل جعبه ابزار مدیریت فرآیند های سازمان</h2>
            <div className="row d-flex align-items-center mt-5 formsaz-container">
                <div className="col-md-4 col-xs-12 align-items-center">
                    <div className='d-flex flex-column align-items-center p-4 formsaz-component'>
                        <img src={Database} alt=""/>
                        <h2 className='mt-3'>گزارش ساز مپسان</h2>
                        <hr />
                        <p>ساخت گزارش از اطلاعات فرم ها</p>
                        <hr />
                        <p>ایجاد انواع الگوی گزارش گیری</p>
                        <hr />
                        <p>دریافت انواع فایل های خروجی </p>
                    </div>
                </div>
                <div className="col-md-4 col-xs-12 align-items-center">
                    <div className='d-flex flex-column align-items-center p-4 formsaz-component'>
                        <img src={Formsaz} alt="" />
                        <h2 className='mt-3'>فرم ساز مپسان</h2>
                        <hr />
                        <p>ایجاد انواع مختلف فیلد عددی، رشته و تاریخ</p>
                        <hr />
                        <p>تعیین فیلدهای اجباری و اعمال محدودیت</p>
                        <hr />
                        <p>لیست مقادیر برای فیلدهای انتخابی محدود</p>
                        <hr />
                        <p>تغییر اندازه فیلدهای نسخه وب و موبایل</p>
                        <hr />
                        <p>فیلدهای کالا،عملیات،تجهیزات ونیروی انسانی</p>
                        <hr />
                        <p>اسلسله مراتب چند سطحی مراکز اجرا</p>
                    </div>
                </div>
                <div className="col-md-4 col-xs-12 align-items-center">
                    <div className='d-flex flex-column align-items-center p-4 formsaz-component'>
                        <img src={Workflow} alt="" />
                        <h2 className='mt-3'>ورکفلو ساز مپسان</h2>
                        <hr />
                        <p>ایجاد انواع گردش کار فرم ها</p>
                        <hr />
                        <p>کارتابل اختصاصی پرسنل سازمان</p>
                        <hr />
                        <p>تفویض اختیار کارتابل وظایف</p>
                    </div>
                </div>
            </div>
        </section>;
}
