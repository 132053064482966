import React, { Component } from "react";
import Infinity from '../resources/images/infinity.svg';
import StopWatch from '../resources/images/stopwatch.svg';
import Image from '../resources/images/image.svg';
import Puzzle from '../resources/images/puzzle.svg';
import FloppyDisk from '../resources/images/floppy-disk.svg';
import LinkHorizontal from '../resources/images/link-horizontal.svg';
import Worker from '../resources/images/person-digging.svg';
import MSP from '../resources/images/microsoft-project.svg';
import ChartGantt from '../resources/images/chart-gantt.svg';
import "../styles/PlanningFeatures.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const featureList = [
  {
    featureIcon: Infinity,
    featureTitle: "تعریف نامحدود پروژه",
    featureDescription:
      "انواع پروژه های سازمان خود را تعریف و آن ها را بر اساس موضوع یا برنامه های سالیانه دسته بندی و وضعیت پیشرفت سبد پروژه ها را رصد کنید.",
  },
  {
    featureIcon: Puzzle,
    featureTitle: "تعریف ساختار شکست کار",
    featureDescription:
    "پروژه های خود را به فازها و فعالیت های کوچکتر شکسته و ساختار شکست پروزه هایتان را در مپسان وارد کنید. اینگونه مطمئن خواهید شد که هیچ فعالیتی از قلم نخواهد افتاد."
  },
  {
    featureIcon: StopWatch,
    featureTitle: "تعریف برنامه زمانبندی پروژه",
    featureDescription:
      "تعریف برنامه زمانبندی در مپسان این امکان را به شما می دهد تا فعالیت های پروژه هایتان را در به موقع تکمیل و تحویل دهید.",
  },
  {
    featureIcon: FloppyDisk,
    featureTitle: "تعریف خط مبنا زمانی و ریالی",
    featureDescription:
      "با استفاده از این امکان میتوانید 20 خط مبنای زمانی و ریالی برای پروژه خود تعریف کرده و عملکرد واقعی و برنامه ریزی شده را مقایشه کنید.",
  },
  {
    featureIcon: LinkHorizontal,
    featureTitle: "ثبت روابط بین فعالیت ها",
    featureDescription:
      "میتوانید انواع روابط پیش نیازی و پس نیازی بین فعالیت های پروژه در را در مپسان تعریف کرده و فعالیت های پروژه را به ترتیب تکمیل نمائید.",
  },
  {
    featureIcon: Image,
    featureTitle: "درج تصاویر و مستندات هر فعالیت",
    featureDescription:
      "به جای نگهداری مستندات و تصاویر مربوط به پروژه در بسترهای متنوع و مختلف، تمامی آن ها را در مپسان بصورت تفکیک شده و در دسترس نگهداری نمایید.",
  },
  {
    featureIcon: Worker,
    featureTitle: "ثبت برآورد مصالح و اجرائیات ",
    featureDescription:
      "منابع مورد نیاز برای انجام فازها و فعالیت ها اعم از مصالح، تجهیزات و نیروی انسانی را به همراه مقدار و حجم مورد نیاز ثبت نمایید.",
  },
  {
    featureIcon: ChartGantt,
    featureTitle: "گانت چارت پروژه",
    featureDescription:
      "با استفاده از گانت چارت پروژه ها در نرم افزار مدیریت پروژه آنلاین مپسان به راحتی وضعیت فازهاو فعالیت های پروژه را در یک نگاه مشاهده نمایید.",
    },
    {
      featureIcon: MSP,
      featureTitle: "ورود اطلاعات از طریق فایل MSP",
      featureDescription:
        "درصورتیکه با نرم افزار مایکروسافت پراچکت احساس راحتی می کنید،فایل های msp برنامه زمانبندی پروژه خود را در مپسان وارد کنید.",
    },
];

export default class PlanningFeaturesComponent extends Component {
  render = () => (
    <section className="plannig-features-section mt-5 ">
              <h2 className="feature-section-title text-center planning-section-title">امکانات برنامه ریزی و کنترل پروژه ها</h2>
      <section className="custom-container">
              <div className="row planning-features-container mt-5">
                {featureList.map((feature, index) => {
                  return (
                    <AnimationOnScroll animateIn="animate__zoomIn" className="col-md-4 p-2">
                  <div className="planning-item p-2">
                    <section className="mobile-feature-header">
                      <span className="planning-item-title">{feature.featureTitle}</span>
                      <img src={feature.featureIcon} className="mobile-feature-icon m-2" alt="Gantticon" width="30" height="30"></img>
                    </section>
                    <hr />
                    <p className="planning-feature-desc">
                      {feature.featureDescription}
                    </p>
                  </div>
              </AnimationOnScroll>
            );
          })}
          </div>
      </section>
    </section>
  );
}