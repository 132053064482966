import React, { Component } from "react";
import BriefCase from '../resources/images/briefcase.svg';
import StopWatch from '../resources/images/stopwatch.svg';
import MapsunMobile from '../resources/images/Mapsun-mobile.webp';
import Location from '../resources/images/location-dot.svg';
import Image from '../resources/images/image.svg';
import Percentage from '../resources/images/percent.svg';
import Box from '../resources/images/box-circle-check.svg';
import CircleCheck from '../resources/images/circle-check.svg';
import ClipboardCheck from '../resources/images/clipboard-check.svg';
import "../styles/MobileFeatures.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const featureListRight = [
  {
    featureIcon: BriefCase,
    featureTitle: "کارتابل وظایف پروژه",
    featureDescription:
      "فعالیت های واگذار شده به اعضای تیم پروژه از طریق کارتابل گوشی هوشمند در دسترس است",
    featureStyle: "mobile-feature-container d-flex flex-column align-items-end mb-3"

  },
  {
    featureIcon: StopWatch,
    featureTitle: "ثبت تایم شیت",
    featureDescription:
      "ثبت زمان صرف شده بر روی پروژه ها و فعالیت های مختلف از طریق طریق گوشی هوشمند",
    featureStyle: "mobile-feature-container d-flex flex-column align-items-start mb-3"

  },
  {
    featureIcon: Location,
    featureTitle: "ثبت موقعیت مکانی",
    featureDescription:
      "کنترل ورود و خروج به کارگاه و دفتر شرکت بر اساس دریافت لوکیشن گوشی هوشمند",
    featureStyle: "mobile-feature-container d-flex flex-column align-items-start mb-3"

  },
  {
    featureIcon: Image,
    featureTitle: "تصاویر و مستندات",
    featureDescription:
      "بارگذاری تصاویر و مستندات هر فعالیت توسط نسخه وب، اپلیکیشن اندروید و IOS",
    featureStyle: "mobile-feature-container d-flex flex-column align-items-end"

    },
];

const featureListLeft = [
  {
    featureIcon: Percentage,
    featureTitle: "ثبت درصد پیشرفت",
    featureDescription:
      "ارسال درصد پیشرفت فعالیت های واگذار شده به اعضای تیم پروژه از طریق گوشی هوشمند",
    featureStyle: "mobile-feature-container d-flex flex-column align-items-start mb-3"
  },
  {
    featureIcon: Box,
    featureTitle: "ثبت رسید و حواله انبار",
    featureDescription:
      "کنترل ورودی و خروجی مصالح از انبار یا ثبت مصرفی در پروژه از طریق گوشی هوشمند",
    featureStyle: "mobile-feature-container d-flex flex-column align-items-end mb-3"

  },
  {
    featureIcon: CircleCheck,
    featureTitle: "تایید پیشرفت پروژه",
    featureDescription:
      "پیشرفت فعالت های ارسال شده توسط اعضای تیم پروژه در موبایل مدیر پروژ مشاده می شود",
    featureStyle: "mobile-feature-container d-flex flex-column align-items-end mb-3"

  },
  {
    featureIcon: ClipboardCheck,
    featureTitle: "ثبت گزارشات کارگاهی",
    featureDescription:
      "گزارش کارگاهی و فرم های کنترل کیفی از محل پروژه قابل ثبت و ارسال می باشد.",
    featureStyle: "mobile-feature-container d-flex flex-column align-items-start"
    },
];

export default class MobileFeaturesComponent extends Component {
  render = () => (
    <section className="mobile-features-section mt-5 ">
      <section className="custom-container my-5 components text-center">
              <h2 className="feature-section-title planning-section-title">ثبت و مشاهده اطلاعات پروژه ها در هر زمان و مکان</h2>
              <div className="row mobile-feature-container mt-5 justify-content-center">
                <div className="d-flex flex-column col-md-4 col-lg-4 col-sm-6">
                {featureListRight.map((feature, index) => {
            return (
              <AnimationOnScroll animateIn="animate__slideInRight">
                <div className={feature.featureStyle}>
                  <div className="mobile-item">
                    <section className="mobile-feature-header">
                      <span className="mobile-item-title">{feature.featureTitle}</span>
                      <img src={feature.featureIcon} className="mobile-feature-icon m-2" alt="Gantticon" width="25" height="25"></img>
                    </section>
                    <hr />
                    <p className="small">
                      {feature.featureDescription}
                    </p>
                  </div>
                </div>
              </AnimationOnScroll>
            );
          })}
                </div>
                <AnimationOnScroll animateIn="animate__slideInUp">
                  <div className="col-md-3 col-sm-12 col-lg-4">
                    <img className="mobile-image" src={MapsunMobile} alt="Mapsun-Mobile-image"/>
                  </div>
                </AnimationOnScroll>
                <div className="d-flex flex-column col-md-4 col-sm-12 col-lg-4">
                {featureListLeft.map((feature, index) => {
            return (
              <AnimationOnScroll animateIn="animate__slideInLeft">
              <div className={feature.featureStyle}>
                <div className="mobile-item">
                  <section className="mobile-feature-header">
                    <img src={feature.featureIcon} className="mobile-feature-icon m-2" alt="Gantticon" width="25" height="25"></img>
                    <span className="mobile-item-title">{feature.featureTitle}</span>
                  </section>
                  <hr />
                  <p className="small">
                    {feature.featureDescription}
                  </p>
                </div>
              </div>
              </AnimationOnScroll>
            );
          })}
                </div>
              </div>
      </section>
    </section>
  );
}