import React, { Component } from "react";
import Hand from "../resources/images/hand-holding-box-thin.svg";
import CubesThin from "../resources/images/cubes-thin.svg";
import Cart from "../resources/images/cart-flatbed-boxes.svg";
import PersonDigging from "../resources/images/person-digging-thin.svg";
import "../styles/MandeKarfarma.css";
import "animate.css/animate.min.css";
import ReportKarfarma from '../resources/images/mandekarfarma.png';
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default class MandeKarfarmaComponent extends Component {
  render = () => (
    <section className="plannig-features-section mt-5 mb-3">
      <section className="row mt-4">
        <div className="col-md-7">
            <img src={ReportKarfarma} className="mandekarfarma-image" alt="گزارش مانده کالا کارفرما در پروژه" width={1000} height={600} />
        </div>
        <div className="col-md-5 col-xs-12 d-flex flex-column justify-content-center mandekarfarma-features-container">
          <AnimationOnScroll animateIn="animate__slideInLeft">
          <div className="mb-4">
             <h2 className="planning-section-title">گزارش مانده کالا و مصالح در پروژه</h2>
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__slideInLeft">
          <div className="mandekarfarma-features p-3 mb-4">
            <img src={Hand} alt="" width={45} height={45}/>
            <div className="vl"></div>
            <h3>مقادیر کالا و مصالح امانی کارفرما در پروژه</h3>
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__slideInLeft">
          <div className="mandekarfarma-features p-3 mb-4">
            <img src={CubesThin} alt="" width={45} height={45}/>
            <div className="vl"></div>
            <h3>مقادیر کالا و مصالح تامین پیمانکار در پروژه</h3>
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__slideInLeft">
          <div className="mandekarfarma-features p-3 mb-4">
            <img src={Cart} alt="" width={45} height={45}/>
            <div className="vl"></div>
            <h3>مقادیر انتقال کالا و مصالح بین پروژه ها</h3>
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__slideInLeft">
          <div className="mandekarfarma-features p-3">
            <img src={PersonDigging} alt="" width={45} height={45}/>
            <div className="vl"></div>
            <h3>مقادیر مصرفی کالا و مصالح در پروژه ها</h3>
          </div>
          </AnimationOnScroll>
        </div>
      </section>
    </section>
  );
}