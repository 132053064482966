import React, { Component } from "react";
import NavbarComponent from "./NavbarComponent";
import Logo from '../resources/images/logo-white.webp';
import LogoWhite from '../resources/images/logo-white.webp';
import SidebarComponent from "./SidebarComponent";

// const Logo = "https://mapsunhomefiles.s3.ir-thr-at1.arvanstorage.com/logo.webp";
// const LogoWhite = "https://mapsunhomefiles.s3.ir-thr-at1.arvanstorage.com/logowhite.webp";

class LogoAndNavbarComponent extends Component {

  // renderLogo() {
  //   if (this.props.forAll)
  //     return (
  //       <div className="col-12 col-md-1 justify-content-center d-flex d-md-block">
  //         <img src={LogoWhite} alt="مپسان" height="80" width="125" />
  //       </div>
  //     );
  //   return (
  //     <div className="col-12 col-md-1 justify-content-center d-flex d-md-block">
  //       <img
  //         src={Logo}
  //         alt="مپسان"
  //         height="80"
  //         width="135"
  //         className="d-none d-md-block"
  //       />
  //       <img
  //         src={LogoWhite}
  //         alt="مپسان"
  //         height="80"
  //         width="125"
  //         className="d-md-none"
  //       />
  //     </div>
  //   );
  // }

  render() {
    return (
      <div className="row pt-5 div-navbar">
        {/* {this.renderLogo()} */}
        <div className="col-md-12 d-none d-md-block">
          <NavbarComponent />
        </div>
        <div className="d-md-none">
          <SidebarComponent />
        </div>
      </div>
    );
  }
}

export default LogoAndNavbarComponent;
