import React, { Component } from "react";
import Sack from '../resources/images/sack-dollar.svg';
import File from '../resources/images/file-invoice-dollar.svg';
import HandBox from '../resources/images/hand-holding-box.svg';
import Helmet from '../resources/images/helmet-safety.svg';
import Branch from '../resources/images/code-branch.svg';
import Diagram from '../resources/images/diagram-subtask.svg';
import Location from '../resources/images/location-crosshairs.svg';
import Circle from '../resources/images/circle-check.svg';
import Fork from '../resources/images/code-fork.svg';
import EstimateCircle from '../resources/images/circle.png';
import EstimateMan from '../resources/images/Estimate-man.webp';
import EstimateMen from '../resources/images/Estimate-men.webp';
import "../styles/PlanningFeatures.css";
import "../styles/EstimateSection.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const featureList = [
  {
    featureIcon: Sack,
    featureTitle: "برآورد اولیه طرح ها",
    featureDescription:
      "با استفاده از ماژول متره و برآورد نرم افزار مدیریت پروزه مپسان میتوانید برآورد اولیه طرح های پروژه یا قراردادها را ثبت و محاسبه کنید.",
  },
  {
    featureIcon: File,
    featureTitle: "استفاده از فهارس بها",
    featureDescription:
    "محاسبه برآورد پروژه را با استفاده از فهارس بها سال های مختلف که در مپسان از پیش تعریف شده اند را انجام دهید و در یک قرارداد از چندین مرجع استفاده نمائید."
  },
  {
    featureIcon: HandBox,
    featureTitle: "تفکیک مصالح تامین کارفرما",
    featureDescription:
      "فرم های متره و برآورد نرم افزار مدیریت پروژه مپسان بگونه ای طراحی شده اند که بتوانید مقدار کالا و مصالح برآوردی تامین شده توسط کارفرما را به تفکیک ثبت نمائید.",
  },
  {
    featureIcon: Helmet,
    featureTitle: "ماشین آلات و نیروی انسانی",
    featureDescription:
      "علاوه بر امکان ثبت متره و برآورد مصالح مصرفی و عملیات اجرائی امکان ثبت ماشین آلات و نیروی انسانی نیز در فرم های متره برآورد تعبیه شده است.",
  },
  {
    featureIcon: Branch,
    featureTitle: "محاسبه اتوماتیک رکوردها",
    featureDescription:
      "میتوانید یک رکورد مرجع انتخاب و چندین رکورد وابسته به آن را لینک کنید تا با ثبت رکورد مرجع، رکوردهای وابسته بصورت اتوماتیک محاسبه گردد.",
  },
  {
    featureIcon: Location,
    featureTitle: "برآورد به تفکیک مراکز اجرا",
    featureDescription:
      "میتوان متره و برآورد را به منظور تفکیک برآورد و محاسبه بر حسب مراکز اجرای پروژه مثلا بلوک طبقه واحد و... انتخاب و محاسبه گردد.",
  },
  {
    featureIcon: Diagram,
    featureTitle: "برآورد به تفکیک فازهای پروژه",
    featureDescription:
      "ارتباط مستقیمی میان برنامه ریزی پروژه، ساختار شکست کار و متره برآورد پروژه ایجاد شده که شما میتوانید متره و برآورد را به تفکیک فازها و فعالیت های پروژه خود ثبت نمائید.",
  },
  {
    featureIcon: Circle,
    featureTitle: "امکان تایید و خروج از تایید",
    featureDescription:
      "کاربران دارای دسترسی میتوانند فرم های متره و برآورد پروژه را تایید و یا در صورت نیاز به اصلاحات خروج از تایید نمایند.",
  },
  {
    featureIcon: Fork,
    featureTitle: "گردش کار فرم متره و برآورد",
    featureDescription:
      "به منظور بررسی، اصلاح و یا تایید فرم های متره و برآورد امکان گردش کاری میان پست های سازمانی تعریف شده و میتوان فرم را با گردشی دلخواه در سازمان به گردش درآورد.",
    },
];

export default class EstimateFeaturesComponent extends Component {
  render = () => (
    <section className="estimate-features-section d-flex flex-column pt-5 ">
              <h2 className="feature-section-title text-center planning-section-title">امکانات متره و برآورد پروژه</h2>
      <section className="custom-container">
              <div className="row planning-features-container mt-5 p-2">
                {featureList.map((feature, index) => {
                  return (
                    <AnimationOnScroll animateIn="animate__zoomIn" className="col-md-4 p-2">
                  <div className="planning-item p-2">
                    <section className="mobile-feature-header">
                      <span className="planning-item-title">{feature.featureTitle}</span>
                      <img src={feature.featureIcon} className="mobile-feature-icon m-2" alt="Gantticon" width="30" height="30"></img>
                    </section>
                    <hr />
                    <p className="planning-feature-desc">
                      {feature.featureDescription}
                    </p>
                  </div>
              </AnimationOnScroll>
            );
          })}
          </div>
      </section>
      <section className="row estimate-pros-section d-flex justify-content-center align-items-center mb-5">
          <div className="col-md-6 estimate-images-container mb-5">
              <img src={EstimateCircle} alt="estimate circle" className="estimate-circle"/>
            <img src={EstimateMen} alt="estimate men" className="estimate-men"/>
            <img src={EstimateMan} alt="estimate man" className="estimate-man"/>
          </div>
          <div className="col-md-6 estimate-text-container">
            <AnimationOnScroll animateIn="animate__slideInLeft">
            <h2 mt-4>مزایای استفاده از متره و برآورد مپسان</h2>
            <p className="mt-4">
            با استفاده از این ابزار می توانید بودجه مورد نیاز اجرای
            پروژه را در سطح فاز و فعالیت بدست آورده و به کمک آن
            و با امکان اتصال بودجه و حسابداری نرم افزار مدیریت
            پروژه مپسان در هر لحظه مقدار بودجه پیش بینی شده
            و هزینه واقعی پروژه را مورد بررسی قرار دهید
            از طرف دیگر با در دست داشتن اطلاعات مقدار مصالح و
            عملیات اجرائی مورد نیاز بهتر می توانید برای آینده
            برنامه ریزی کرده و به منظور تهیه بودجه و خرید مصالح
            .مورد نیاز در آینده زودتر چاره ای بیاندیشید
            </p>
            </AnimationOnScroll>
          </div>
      </section>
    </section>
  );
}