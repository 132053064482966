import React, { Component } from "react";
import PlanningProcess from '../resources/images/PlanningProcess.svg';
import "../styles/PlanningProcess.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';


export default class PlanningProcessComponent extends Component {
  render = () => (
    <section className="plannig-process-section mt-4 ">
        <section className="row custom-container justify-content-start mt-2 ">
            <div className="col-md-6 col-sm-12 d-flex flex-column">
          <AnimationOnScroll animateIn="animate__slideInRight">
                <div className="mb-5">
                  <h2 className="feature-section-title text-center planning-section-title">مراحل ثبت پیشرفت فعالیت های پروژه</h2>
                </div>
                <div className="mb-5">
                  <h3>قدم اول - تعریف پروژه و تخصیص متصدی فعالیت</h3>
                  <hr className="mt-2 mb-3" />
                  <p>برنامه زمانبندی پروژه در نرم افزار مدیریت پروژه مپسان تعریف گردیده 
                  و برای هر فعالیت یک متصدی یا مسئول انجام فعالیت انتخاب می گردد.
                   با انتخاب متصدی، فعالیت مذکور در کارتابل اختصاصی ایشان قرار می گیرد.</p>
                </div>
                <div className="mb-5">
                  <h3>قدم دوم - ثبت پیشرفت توسط متصدی در کارتابل پروژه</h3>
                  <hr className="mt-2 mb-3"/>
                  <p> کاربر فعالیت های واگذار شده به وی را در کارتابل تحت وب یا اپلیکیشن موبایل خود مشاهده کرده
                    و نسبت به انجام فعالیت های آماده محوله اقدام می نماید. بعد از پیشرفت یا تکمیل فعالیت،
                    درصد پیشرفت به همراه توضیحات و مستندات به منظور تایید برای مدیر پروژه ارسال می نماید. </p>
                </div>
                <div>
                  <h3>قدم سوم - تایید یا رد پیشرفت توسط مدیر پروژه در کارتابل تاییدات</h3>
                  <hr className="mt-2 mb-3"/>
                  <p>مدیر پروژه با مراجعه به کارتابل تحت وب یا اپلیکیشن موبایل خود
                    ، فعالیت های ارسال شده توسط متصدیان انجام فعالیت ها را به همراه توضیحات و مستندات مربوطه مشاهده کرده
                    و در صورتیکه مورد تایید وی قرار بگیرد آن را تایید و در غیر اینصورت آن را رد می کند.</p>
                </div>
          </AnimationOnScroll>
            </div>
            <div className="col-md-6 mt-5 col-sm-0 planning-process-image">
          <AnimationOnScroll animateIn="animate__slideInLeft">
                  <img src={PlanningProcess} alt="planning-process-image" className="mt-5 planning-process-image"/>
          </AnimationOnScroll>
            </div>
        </section>
    </section>
  );
}