import React, { Component } from "react";
import SliderComponent from "./SliderComponent";

export default class CarouselComponent extends Component {
  render = () => (
    <section className="container custom-mt-100px">
      <h3 className="text-center mb-5">برخی از مشتریان</h3>
      <div className="row">
        <SliderComponent />
      </div>
    </section>
  );
}
