import React, { Component } from "react";
import Gauge from "../resources/images/gauge-circle-bolt.svg";
import ChartSimpleHor from "../resources/images/chart-simple-horizontal.svg";
import DiagramSanky from "../resources/images/diagram-sankey.svg";
import PieChartThin from "../resources/images/chart-pie-thin.svg";
import "../styles/MandeKarfarma.css";
import "animate.css/animate.min.css";
import ReportKarfarma from '../resources/images/dashboard-frame_1.webp';
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default class DashboardComponent extends Component {
  render = () => (
    <section className="plannig-features-section mt-5 mb-3">
      <section className="row mt-4">
        <div className="col-md-7">
            <img src={ReportKarfarma} className="mandekarfarma-image" alt="گزارش مانده کالا کارفرما در پروژه" width={1000} height={600} />
        </div>
        <div className="col-md-5 d-flex flex-column justify-content-center mandekarfarma-features-container">
          <AnimationOnScroll animateIn="animate__slideInLeft">
          <div className="mb-4">
             <h2 className="planning-section-title">امکانات داشبورد پروژه های مپسان</h2>
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__slideInLeft">
          <div className="mandekarfarma-features p-3 mb-4">
            <img src={Gauge} alt="" width={45} height={45}/>
            <div className="vl"></div>
            <h3>خلاصه وضعیت پیشرفت، تاخیر، بودجه و...</h3>
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__slideInLeft">
          <div className="mandekarfarma-features p-3 mb-4">
            <img src={ChartSimpleHor} alt="" width={45} height={45}/>
            <div className="vl"></div>
            <h3>نمودار برآورد ریالی به تفکیک فازهای پروژه</h3>
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__slideInLeft">
          <div className="mandekarfarma-features p-3 mb-4">
            <img src={DiagramSanky} alt="" width={45} height={45}/>
            <div className="vl"></div>
            <h3>S-Curve پیشرفت واقعی و Baseline پروژه</h3>
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__slideInLeft">
          <div className="mandekarfarma-features p-3">
            <img src={PieChartThin} alt="" width={45} height={45}/>
            <div className="vl"></div>
            <h3>گزارشات بر اساس سرفصل های حسابداری</h3>
          </div>
          </AnimationOnScroll>
        </div>
      </section>
    </section>
  );
}