import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../styles/Navbar.css";
// import "../styles/duotone.min.css"
import AngleDown from "../resources/images/angle-down.svg";
import LogoWhite from "../resources/images/logo-white.webp";
import DropDownNavbarComponent from "./DropDownNavbarComponent";

class NavbarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      relatedLinks: {
        training: [
          {
            link: "/base-def",
            linkText: "تعاریف پایه",
            childs: [
              {
                link: "/inventory",
                linkText: "انبارها",
              },
              {
                link: "/human-and-machines",
                linkText: "تجهیزات و نیروی انسانی",
              },
              {
                link: "/organization-chart-and-users",
                linkText: "چارت سازمانی و کاربران",
                childs: [
                  {
                    link: "/delegate",
                    linkText: "جانشینی",
                  },
                  {
                    link: "/organization-chart",
                    linkText: "چارت سازمانی",
                  },
                  {
                    link: "/users-management",
                    linkText: "مدیریت کاربران",
                  },
                  {
                    link: "/login-process",
                    linkText: "ورود به سیستم",
                  },
                ],
              },
              {
                link: "/locations",
                linkText: "شهرستان ها",
              },
              {
                link: "/material-and-services",
                linkText: "کالاها و عملیات",
              },
              {
                link: "/material-and-service-groups",
                linkText: "گروه های کالا و عملیات",
              },
              {
                link: "/execution-centers",
                linkText: "مراکز اجرا",
              },
            ],
          },
          {
            link: "/accounting-training",
            linkText: "امور مالی",
            childs: [
              {
                link: "/accounting-documents",
                linkText: "اسناد حسابداری",
              },
              {
                link: "/payment",
                linkText: "تنخواه گردان",
              },
              {
                link: "/accounts",
                linkText: "سرفصل های حسابداری",
              },
              {
                link: "/invoice",
                linkText: "فاکتورهای خرید و فروش",
              },
              {
                link: "/accounting-reports",
                linkText: "گزارشات مالی",
              },
              {
                link: "/check-management",
                linkText: "مدیریت چک",
              },
            ],
          },
          {
            link: "/logistics-and-purchase",
            linkText: "تدارکات و خرید",
            childs: [
              {
                link: "/purchase-request-mobile",
                linkText: "ثبت درخواست از موبایل",
              },
              {
                link: "/purchase-request",
                linkText: "ثبت درخواست خرید",
              },
              {
                link: "/purchase-reports",
                linkText: "گزارشات خرید",
              },
            ],
          },
          {
            link: "/contracts",
            linkText: "پیمانکاران و قراردادها",
            childs: [
              {
                link: "/contract-warranty",
                linkText: "تضامین قراردادها و مناقصات",
              },
              {
                link: "/contractors-management",
                linkText: "مدیریت پیمانکاران",
              },
              {
                link: "/contracts-management",
                linkText: "مدیریت قراردادها",
              },
            ],
          },
          {
            link: "/cost-estimation",
            linkText: "متره و برآورد",
            childs: [
              {
                link: "/estimation-entry",
                linkText: "ثبت برآورد",
              },
              {
                link: "/cost-list",
                linkText: "فهرست بها",
              },
              {
                link: "/estimation-reports",
                linkText: "گزارشات برآورد",
              },
            ],
          },
          {
            link: "/material-flow",
            linkText: "گردش کالا",
            childs: [
              {
                link: "/material-flow-forms",
                linkText: "فرم های گردش کالا",
                childs: [
                  {
                    link: "/material-in-out",
                    linkText: "رسید و حواله انبار",
                  },
                  {
                    link: "/flow-between-inventories",
                    linkText: "انتقال بین انبارها",
                  },
                  {
                    link: "/return-material",
                    linkText: "برگشت کالا",
                  },
                  {
                    link: "/material-remittance-minute",
                    linkText: "پیش نویس حواله",
                  },
                ],
              },
              {
                link: "/material-flow-invoice",
                linkText: "فاکتورهای خرید و فروش",
              },
              {
                link: "/employer-material-control",
                linkText: "کنترل کالای تامین کارفرما",
              },
              {
                link: "/material-flow-reports",
                linkText: "گزارشات کنترل کالا",
                childs: [
                  {
                    link: "/employer-material-reports",
                    linkText: "گزارشات کالای تامین کارفرما",
                  },
                ],
              },
            ],
          },
          {
            link: "/supervision",
            linkText: "نظارت",
            childs: [
              {
                link: "/status-statement",
                linkText: "صورت وضعیت",
              },
              {
                link: "/supervision-minute",
                linkText: "صورتجلسه نظارتی",
              },
              {
                link: "/supervision-general",
                linkText: "عمومی نظارت",
              },
              {
                link: "/site-supervision-forms	",
                linkText: "فرم های نظارت کارگاهی",
                childs: [
                  {
                    link: "/supervision-forms",
                    linkText: "فرم های نظارتی",
                  },
                  {
                    link: "/check-values",
                    linkText: "کنترل مقادیر",
                  },
                  {
                    link: "/supervision-forms-mobile",
                    linkText: "نسخه موبایل",
                  },
                ],
              },
              {
                link: "/supervision-reports",
                linkText: "گزارشات ماژول نظارت",
              },
              {
                link: "/operation-meters",
                linkText: "متره اجرا",
              },
            ],
          },
          {
            link: "/project-control-training",
            linkText: "کنترل پروژه",
            childs: [
              {
                link: "/project-entry",
                linkText: "ثبت پروژه ها",
              },
              {
                link: "/project-wbs",
                linkText: "تعریف ساختار شکست کار پروژه",
              },
              {
                link: "/import-msp",
                linkText: "ورود اطلاعات از MSP",
              },
              {
                link: "/project-tasks-cartabl",
                linkText: "کارتابل وظایف کنترل پروژه",
              },
              {
                link: "/project-tasks-progress",
                linkText: "ثبت پیشرفت فعالیت های پروژه",
              },
              {
                link: "/project-progress-confirmation",
                linkText: "تایید پیشرفت فعالیت های پروژه",
              },
            ],
          },
          {
            link: "/reports",
            linkText: "گزارشات",
            childs: [
              {
                link: "/accounting-reports-2",
                linkText: "گزارشات مالی",
              },
              {
                link: "/logistics-reports-2",
                linkText: "گزارشات خرید و تدارکات",
              },
              {
                link: "/contracts-reports-2",
                linkText: "گزارشات قراردادها و پیمانکاران",
              },
              {
                link: "/estimation-reports-2",
                linkText: "گزارشات برآورد",
              },
              {
                link: "/supervision-reports-2",
                linkText: "گزارشات نظارت",
              },
              {
                link: "/material-flow-reports-2",
                linkText: "گزارشات گردش کالا",
              },
              {
                link: "/project-control-reports-2",
                linkText: "گزارشات کنترل پروژه",
              },
            ],
          },
          {
            link: "/workflow",
            linkText: "گردش های کاری",
            childs: [
              {
                link: "/workflow-definition",
                linkText: "تعریف گردش های کاری",
              },
              {
                link: "/workflow-delegate",
                linkText: "جانشینی",
              },
              {
                link: "/workflow-cardboard",
                linkText: "کارتابل گردش کار",
              },
              {
                link: "/workflow-mobile",
                linkText: "نسخه موبایل",
              },
            ],
          },
          {
            link: "/system-management",
            linkText: "مدیریت سیستم",
            childs: [
              {
                link: "/access-management",
                linkText: "مدیریت دسترسی",
              },
              {
                link: "/admin-organization-chart-and-users",
                linkText: "چارت سازمانی و کاربران",
                childs: [
                  {
                    link: "/admin-delegate",
                    linkText: "جانشینی",
                  },
                  {
                    link: "/admin-organization-chart",
                    linkText: "چارت سازمانی",
                  },
                  {
                    link: "/admin-users-management",
                    linkText: "مدیریت کاربران",
                  },
                  {
                    link: "/admin-login-process",
                    linkText: "ورود به سیستم",
                  },
                ],
              },
            ],
          },
          {
            link: "/meeting-management",
            linkText: "مدیریت جلسات",
            childs: [
              {
                link: "/create-meeting",
                linkText: "ایجاد و ثبت اطلاعات جلسه",
              },
              {
                link: "/meeting-reports",
                linkText: "گزارشات جلسات",
              },
            ],
          },
        ],
        posts: [
          {
            link: "/principles-of-management",
            linkText: "اصول مدیریت",
          },
          {
            link: "/project-management-knowledge",
            linkText: "دانش مديريت پروژه",
          },
          {
            link: "/strategy-management",
            linkText: "مدیریت استراتژیک",
          },
          {
            link: "/construction-project-management",
            linkText: "مدیریت پروژه های عمرانی",
          },
          // {
          //   link: "/agile-project-management",
          //   linkText: "مدیریت پروژه چابک",
          // },
        ],
        systems: [
          {
            link: "/نرم-افزار-پیمانکاری",
            linkText: "نرم افزار پیمانکاری",
          },
          {
            link: "/نرم-افزار-تایم-شیت-مپسان",
            linkText: "نرم افزار تایم شیت مپسان",
          },
          {
            link: "/سیستم-مدیریت-پروژه",
            linkText: "سیستم مدیریت پروژه",
          },
          {
            link: "/نرم-افزار-مدیریت-قراردادها-و-پیمانکار",
            linkText: "نرم افزار مدیریت قراردادها و پیمانکاران",
          },
        ],
      },
    };
  }

  render = () => (
    <nav className="navbar">
      <ul className="navbar-nav">
        <li className="navbar-brand">
          <img
            className="navbar-logo"
            src={LogoWhite}
            alt="مپسان"
            height="80"
            width="125"
          />
        </li>
        <li className="nav-item">
          <Link to={"/"} className="nav-link">
            خانه
          </Link>
        </li>
        <li className="nav-item dropdown-item-0">
          <a href="/posts/" className="nav-link">
            نوشته ها
            <img
              src={AngleDown}
              className="nav-arrow"
              alt="angledown"
              height="20"
              width="20"
            ></img>
          </a>
          <DropDownNavbarComponent
            relatedLinks={this.state.relatedLinks.posts}
            link="/posts"
          />
        </li>
        {/* <li className="nav-item dropdown-item-0">
                    <a href="/user-manual/" className="nav-link">امکانات</a>
                    <DropDownNavbarComponent relatedLinks={ this.state.relatedLinks.userManual } />
                </li> */}
        <li className="nav-item dropdown-item-0">
          <a href="/training/" className="nav-link">
            مرکز آموزش
            <img
              src={AngleDown}
              className="nav-arrow"
              alt="angledown"
              height="20"
              width="20"
            ></img>
          </a>
          <DropDownNavbarComponent
            relatedLinks={this.state.relatedLinks.training}
            link="/training"
          />
        </li>
        <li className="nav-item dropdown-item-0">
          <a href="/subsystems/" className="nav-link">
            سیستم ها
            <img
              src={AngleDown}
              className="nav-arrow"
              alt="angledown"
              height="20"
              width="20"
            ></img>
          </a>
          <DropDownNavbarComponent
            relatedLinks={this.state.relatedLinks.systems}
            link=""
          />
        </li>
        <li className="nav-item">
          <a href="/pricelist/" className="nav-link">
            لیست قیمت
          </a>
        </li>
      </ul>
      {/* <a className="navbar-brand" href="tel:+982171053605">
                <span>021-71053605</span>
            </a> */}
    </nav>
  );
}

export default NavbarComponent;
