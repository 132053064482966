import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import '../styles/ScrollToTop.css';

export default class ScrollToTopComponent extends Component
{
    constructor(props) {
        super(props);
        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount = () =>
        document.addEventListener('scroll', this.onScroll, false);

    onScroll() {
        if (document.body.scrollTop > 200 ||document.documentElement.scrollTop > 200)
            document.querySelector('#btn-scroll').classList.remove('d-none');
        else
            document.querySelector('#btn-scroll').classList.add('d-none');
    }
        
    componentWillUnmount = () =>
        document.removeEventListener('scroll', this.onScroll, false);

    scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    render = () =>
        <button id="btn-scroll" type="button" className="d-none" onClick={ this.scrollToTop }>
            <FontAwesomeIcon icon={ faArrowUp } />
        </button>;
}
