import React, { Component } from "react";
import ProcurementLeft from "../resources/images/procurement.png";
import ProcurementRight from "../resources/images/procurement.svg";
import "../styles/Procurement.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default class ProcurementProcessComponent extends Component {
  render = () => (
    <section className="plannig-features-section">
      <section className="row mt-5 d-flex justify-content-center align-items-center">
        <div className="col-md-6">
          <img src={ProcurementRight} alt="" className="procurement-right" />
        </div>
        <div className="procurement-middle-container">
        <AnimationOnScroll animateIn="animate__zoomIn">
          <div className="procurement-middle">
            <p>فرآیند خرید</p>
          </div>
        </AnimationOnScroll>
        </div>
        <div className="col-md-6">
          <img src={ProcurementLeft} alt="" className="procurement-left"/>
        </div>
      </section>
    </section>
  );
}